<template>
  <el-card>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="参数配置" name="1">
        <el-card>
          <Parameter />
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="支付配置" name="2">
        <el-card>
          <Pay />
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="商品同步" name="3">
        <el-card>
          <el-button type="primary" @click="syncGoods()">一键同步</el-button>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import Parameter from "./components/parameter.vue";
import Pay from "./components/pay.vue";
import { goodsWeimobSync } from "@/api/goods/goods";
export default {
  name: "config",
  components: {
    Parameter,
    Pay,
  },
  data() {
    return {
      activeName: "1",
      form: {},
    };
  },
  mounted() {},
  methods: {
    handleClick() {},
    syncGoods() {
      this.$confirm('同步时间过长请耐心等待！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        goodsWeimobSync().then(res => {
          if(res.code === 200){
            this.$message.success('操作成功')
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
  },
};
</script>

<style scoped>
</style>
