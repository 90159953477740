<template>
  <div>
    <avue-form
        :option="option"
        v-model="form"
        ref="form"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-before="uploadBefore"
        :upload-after="uploadAfter"
      >
        <template slot="headPortrait">
          <FormImgUpload
            :url="form.headPortrait"
            @upload="upload"
            :disabled="true"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
          <div  class="tipImg">尺寸推荐:100X100，格式：jpeg,png,jpg</div>
        </template>
        <template slot="menuForm">
          <div style="text-align: left;">
          <el-button icon="el-icon-close" style="margin-left: 115px;" @click="handleClose">取消</el-button>
          <el-button type="primary" icon="el-icon-check" @click="submit(form)"
            >确定</el-button
          >
          </div>
        </template>
      </avue-form>
  </div>
</template>

<script>
import {
  appletConfigView,
  appletConfigSaveOrUpdate
} from "@/api/system/config";
import FormImgUpload from "@/components/formComponents/formImgUpload";
export default {
  name: "parameter",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      activeName:'1',
      form:{
        headPortrait:[]
      },
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
        {
            label: "APPID",
            prop: "appid",
            maxlength: 20,
            disabled:true,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入APPID",
                trigger: "blur",
              },
            ],
          },
          {
            label: "密钥",
            prop: "secretKey",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            disabled:true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入密钥",
                trigger: "blur",
              },
            ],
          },
          {
            label: "logo",
            row: true,
            type: "text",
            disabled:true,
            prop: "headPortrait",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传logo",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "管理员",
            prop: "administrators",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入管理员",
                trigger: "blur",
              },
            ],
          },
          {
            label: "手机",
            prop: "administratorsPhone",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入手机",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
   this.getList()
  },
  methods: {
    uploadDelete() {
      // return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.image = res.image;
      // this.$nextTick(() => {
      //   this.form.image = res.path;
      // });
    },
        // 列表
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.headPortrait = [...e];
      } else {
        this.form.headPortrait = [...this.form.headPortrait, e];
      }
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.image = [];
      this.form.isShow = 0;
      this.dialogVisible = false;
    },
    getList() {
      appletConfigView(this.tableFrom)
        .then((res) => {
          this.form = res.data;
          if(res.data.logo){
            this.form.headPortrait = [res.data.logo];
          }

        })
    },
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;
          appletConfigSaveOrUpdate({...this.form,logo:this.form.headPortrait[0]})
              .then((res) => {
                // 获取数据成功后的其他操作
                // console.log(res);
                if (res.code === 200) {
                  this.$message.success("修改成功");
                  this.getList()
                  // done();
                } else {
                  that.$message.error(res.msg);
                  // done();
                }
              })
              .catch((err) => {
                that.$message.error(err.msg);
                // done();
              });
          this.form.type = 1;
          // done()
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
